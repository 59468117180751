const ERROR_COLOR = "#bf2c15"
const WARNING_COLOR = "#bfab15"

const PRIMARY_HEX_CODE = "#1564bf"
const SECONDARY_HEX_CODE = "#1c4378"

export const THEME = {
  primary: PRIMARY_HEX_CODE,
  secondary: SECONDARY_HEX_CODE, 
  warning: WARNING_COLOR,
  error: ERROR_COLOR,
}

export const TOPBAR_OFFSET = "92vh"
export const TOPBAR_HEIGHT = 60

const DASHBOARD_TITLE = {
  fontSize: "1.7rem",
}

const MODAL_FIELD_WIDTH = 'min(80vw, 500px)'

const TABLE_TITLE = {
  fontSize: "1.3rem",
}


const VALID_FREQUENCIES = ["Daily", "Biweekly", "Weekly", "Monthly", "Not Set"]

const SUPPORT_EMAIL = "support@tellescope.com"

const PALETTE = [
  PRIMARY_HEX_CODE,
  SECONDARY_HEX_CODE,
  "#15bf70", // tealish
  "#bf642c", // light orange
  "#bf5915", // darker orange
  "#bfab15", // dark yellow
  "#4215bf", // blurple
  "#9715bf", // purple
  "#bf153d", // pinkish red
  "#008d8d", // ~dark cyan 
  ERROR_COLOR,
  WARNING_COLOR,
]

const ERROR_WHILE_LOADING = 0
type Unloaded = undefined | typeof ERROR_WHILE_LOADING

export const ALL_ACCESS = "All"
export const ASSIGNED_ACCESS = "Assigned"
export const NO_ACCESS  = null // default, when no access explicitly provided is NO_ACCESS

export const FULL_ACCESS = {   
  create: ALL_ACCESS,
  read:   ALL_ACCESS,
  update: ALL_ACCESS,
  delete: ALL_ACCESS,
}
export const FULL_ASSIGNED_ACCESS = {
  create: ASSIGNED_ACCESS, // allow for creating self-assigned objects
  read:   ASSIGNED_ACCESS,
  update: ASSIGNED_ACCESS,
  delete: ASSIGNED_ACCESS,
}
export const INACCESSIBLE = {
  create: NO_ACCESS,
  read:   NO_ACCESS,
  update: NO_ACCESS,
  delete: NO_ACCESS,
}
export const READ_ONLY = {
  create: NO_ACCESS,
  read:   FULL_ACCESS,
  update: NO_ACCESS,
  delete: NO_ACCESS,
}

export const [MS_PER_SECOND, MS_PER_MINUTE, MS_PER_HOUR, MS_PER_DAY] = [1000, 60000, 3600000, 86400000]

export {
  DASHBOARD_TITLE,
  ERROR_COLOR,
  ERROR_WHILE_LOADING,
  MODAL_FIELD_WIDTH,
  PALETTE,
  PRIMARY_HEX_CODE,
  SECONDARY_HEX_CODE,
  SUPPORT_EMAIL,
  TABLE_TITLE,
  WARNING_COLOR,
  VALID_FREQUENCIES,
}

export type { Unloaded }